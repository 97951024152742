.outer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inner {
    position: relative;
    max-width: 100%;
    min-height: 1px;
}

.icon {
    margin-bottom: 1rem;
}

.label {
    font-size: 2rem;
    font-weight: 100;
}
