/* 
Этот класс используется для анимации иконки загрузки во время ожидания ответа от сервера 
Какого черта я положил его сюда? Не знаю, наверное надо утащить к иконке загрузки.    
*/
.animation-spin {
    animation: keyframes-spin infinite 3s linear
}

@keyframes keyframes-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(365deg);
    }
}