/* Root styles for all application*/
body, html, #root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

#root > * {
    height: 100%;
    width: 100%;
}

body {
    /* позволяет дизайну иметь минимальное ограничение по ширене
     * за счет на мобильных устройствах дизайн не сжимается до состояния когда им невозможно пользоватся,
     * но при этом он не влезает на экран и надо скролить в бок
     * надо заменить на настоящий адаптивный вариант со скрывающимся сайдбаром
     * TODO костыль чтобы пока не делать адаптивный дизайн для смартфонов, убрать
     */
    min-width: 600px;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@import './animation.css';