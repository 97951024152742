.sider {
    border-right: 1px solid #b4deff;
    background: white;
}

.breadcrumb {
    padding: 5px 15px;
}

.content {
    padding: 24px;
    margin: 0;
    min-height: 280px;
}